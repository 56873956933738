import Image from 'next/image'
// import { useState } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import styles from './services.module.scss'
interface CardProps {
  title: string
  text: string
  image: string
}

export const Card = ({ title, text, image }: CardProps) => {
  const { scrollYProgress } = useScroll()
  // TODO: check
  // const [, setPositionY] = useState<number>(0)

  // scrollYProgress.onChange((y) => setPositionY(y))
  const yPosAnim = useTransform(scrollYProgress, [0, 1], [-10, -150])

  return (
    <div className={styles.card}>
      <div className={styles.image}>
        <Image
          alt={title}
          src={`/${image}`}
          fill
          style={{ objectFit: 'cover' }}
        />
      </div>
      <motion.div className={styles.content} style={{ y: yPosAnim }}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
      </motion.div>
    </div>
  )
}
