import { shuffleArray } from '@utils/shuffleArray'
import { Divider } from './Divider'
import Masonry from 'react-masonry-css'
import { PortfolioItem } from '@components/PortfolioItem/PortfolioItem'
import styles from './projects.module.scss'
import { PortfolioItems, PortfolioItem as PortfolioItemType } from '@types'

export const Projects = ({ items }: PortfolioItems) => {
  const MAX_ITEMS = 6
  const shuffledItems = shuffleArray(items)
  return (
    <section id="projects" className={styles.wrapper}>
      <Divider />
      <Masonry
        breakpointCols={{
          default: 3,
          992: 2,
          768: 1,
        }}
        className="masonryGrid"
        columnClassName="masonryColumn"
      >
        {shuffledItems.map((item, i) => {
          if (i >= MAX_ITEMS) {
            return null
          }
          return <PortfolioItem item={item as PortfolioItemType} key={i} />
        })}
      </Masonry>
    </section>
  )
}
