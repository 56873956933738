import { formatDescription } from '@utils/contentful'
import Link from 'next/link'
import classnames from 'classnames'
import { useUserAgent } from '@utils/useUserAgent'
import styles from './portfolioItem.module.scss'
import { PortfolioItem as ItemType } from '@types'

interface PortfolioItemProps {
  item: ItemType
}

export const PortfolioItem = ({ item }: PortfolioItemProps) => {
  const { title, description, coverImage } = item.fields
  const formattedDescription = formatDescription(description)
  const userAgent = useUserAgent()
  const MAX_DESC_CHARACTERS = 100
  return (
    <div className={styles.item}>
      <Link legacyBehavior href={`/item/${item.id}`}>
        <a
          className={classnames(!userAgent.isSafari && styles.marginFix)}
          href={`/item/${item.id}`}
        >
          <div className={styles.overlay}></div>
          <img
            alt={title}
            className={styles.image}
            src={coverImage.fields.file.url}
          />
          <div className={classnames(styles.details, styles.fadeIn)}>
            <h3>{title}</h3>
            <p>
              {formattedDescription.substring(0, MAX_DESC_CHARACTERS)}
              {formattedDescription.length > MAX_DESC_CHARACTERS && '...'}
            </p>
          </div>
        </a>
      </Link>
    </div>
  )
}
