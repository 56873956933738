/* eslint-disable react/no-unescaped-entities */
import Image from 'next/image'
import classnames from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container } from '@components/Container/Container'
import styles from './about.module.scss'

export const About = () => {
  return (
    <section id="about" className={styles.wrapper}>
      <Container additionalStyles={classnames(styles.about)}>
        <div className={styles.bio}>
          <h3 className={styles.pre}>ABOUT ME</h3>
          <h2 className={styles.title}>Who I am and what I do</h2>
          <p>Hi, I'm Mario Veltri!</p>
          <p>
            Whenever a recruiter asks me if I’m a generalist or a specialist I
            answer: "YES".
          </p>
          <p>Yes, I have a skillset that may qualify me as a generalist.</p>
          <p>
            Yes, I’m specialised in UI. A good UI artist needs to master a
            synergy of skills.
          </p>
          <p>
            My professional path has lead me thought many fields from publishing
            to illustration, 3D, graphic design, CG and VFX.
          </p>
          <p>
            I managed to learn from several disciplines, cause I’m very open
            minded and I always looked for people better than me at something
            and I always looked for schools where I could learn from everyone.
          </p>
          <p>
            I love being productive and work in synergy with the team members. I
            love to find solutions outside the schemes and to overcome technical
            limitations by using different strategies.
          </p>
          <p>
            I think the perfect Artist must not have any wall between his ideas
            and his final result of his works, that’s something that can be
            acquired with years of studies and discipline.
          </p>
          <p>
            My motto is: “love what you do, do what you love and never stop
            dreaming”.
          </p>
          <AnchorLink
            href="#contacts"
            className={classnames('linkAsBtn', 'button')}
          >
            CONTACT ME
          </AnchorLink>
        </div>
        <div className={styles.image}>
          <Image
            alt="Mario Veltri"
            src={`/mario_veltri.jpg`}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>
      </Container>
    </section>
  )
}
