import { motion } from 'framer-motion'
import styles from './progressBar.module.scss'

interface ProgressBarProps {
  title: string
  completed: number
  isVisible: boolean
}

export const ProgressBar = ({
  title,
  completed,
  isVisible,
}: ProgressBarProps) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.percentage}>{completed}%</p>
      <div className={styles.progressBar}>
        <motion.div
          initial={{ width: 0 }}
          animate={isVisible && { width: `${completed}%` }}
          transition={{ duration: 1 }}
          className={styles.filler}
        ></motion.div>
      </div>
    </div>
  )
}
