import classnames from 'classnames'
import TrackVisibility from 'react-on-screen'
import { Container } from '@components/Container/Container'
import { ProgressBar } from './ProgressBar'
import styles from './skills.module.scss'

export const Skills = () => {
  const skills = [
    { title: 'PHOTOSHOP', completed: 92 },
    { title: 'ILLUSTRATOR', completed: 92 },
    { title: 'INDESIGN', completed: 80 },
    { title: 'FIGMA', completed: 65 },
    { title: 'AFTER EFFECTS', completed: 80 },
    { title: 'UNREAL ENGINE', completed: 70 },
    { title: 'UNITY', completed: 50 },
    { title: 'MAYA', completed: 75 },
    { title: 'ZBRUSH', completed: 65 },
    { title: 'SUBSTANCE PAINTER', completed: 55 },
  ]
  return (
    <section id="skills" className={styles.wrapper}>
      <Container additionalStyles={classnames(styles.skills)}>
        <div className={styles.text}>
          <h3 className={styles.pre}>SKILLS</h3>
          <h2 className={styles.title}>Some of my skills</h2>
          <p>
            I’m a very versatile professional with experiences in magazines, UX
            design, online games, digital painting, animation and al the 3D
            productive pipeline. I also got years of experience in leading and
            directing teams of designers.
          </p>
          <a
            href="https://drive.google.com/file/d/1I_F3QIMm0fz0T7quUvU9WpMOthgmjDh1/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
            className={classnames('linkAsBtn', 'button')}
          >
            DOWNLOAD CV
          </a>
        </div>
        <div className={styles.bars}>
          <TrackVisibility>
            {({ isVisible }) =>
              skills.map((skill, i) => (
                <ProgressBar
                  key={i}
                  title={skill.title}
                  completed={skill.completed}
                  isVisible={isVisible}
                />
              ))
            }
          </TrackVisibility>
        </div>
      </Container>
    </section>
  )
}
