import { Container } from '@components/Container/Container'
import styles from './projects.module.scss'

export const Divider = () => {
  return (
    <div className={styles.divider}>
      <Container>
        <h3 className={styles.pre}>MY WORKS</h3>
        <h2 className={styles.title}>Some Of My Projects</h2>
      </Container>
    </div>
  )
}
