import classnames from 'classnames'
import { Container } from '@components/Container/Container'
import { Card } from './Card'
import styles from './services.module.scss'

export const Services = () => {
  const items = [
    {
      title: 'Brand Image Management',
      text:
        'High-end solutions for Corporate design consultancy and creation service of all the communication elements.',
      image: 'brand_management.jpg',
    },
    {
      title: 'Game Assets Development',
      text:
        'From the game logo to the UX/UI design, from the 2D/3D artwork to the “rigged, textured and animated” asset.',
      image: 'game_development.jpg',
    },
    {
      title: 'CG Pipeline and VFX',
      text:
        'I offer support service and workforce for the following tasks inside a CG/VFX production pipeline.',
      image: 'vg_pipeline.jpg',
    },
  ]

  return (
    <section className={styles.wrapper}>
      <Container additionalStyles={classnames(styles.services)}>
        {items.map((item, i) => (
          <Card
            key={i}
            title={item.title}
            text={item.text}
            image={item.image}
          />
        ))}
      </Container>
    </section>
  )
}
