import classnames from 'classnames'
import { motion } from 'framer-motion'
import { Container } from '@components/Container/Container'
import styles from './contacts.module.scss'

export const Contacts = () => {
  return (
    <section id="contacts" className={styles.wrapper}>
      <Container additionalStyles={classnames(styles.contacts)}>
        <motion.a
          className={styles.card}
          whileHover={{ scale: 1.1 }}
          href="https://www.google.com/maps/place/Munich/data=!4m2!3m1!1s0x479e75f9a38c5fd9:0x10cb84a7db1987d?sa=X&ved=2ahUKEwitlZSunsbvAhUMtxQKHeBkCiUQ8gEwAHoECAQQAQ"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/iconBeer.svg" alt="Icon beer" />
          <h3 className={styles.text}>Where I am</h3>
          <p className={styles.address}>Munich / Germany</p>
        </motion.a>
        <motion.a
          className={styles.card}
          whileHover={{ scale: 1.1 }}
          href="mailto:marioveltri.ui@gmail.com"
        >
          <img src="/iconMail.svg" alt="Icon mail" />
          <h3 className={styles.text}>Write me here</h3>
          <p className={styles.address}>marioveltri.ui@gmail.com</p>
        </motion.a>
        <motion.a
          className={styles.card}
          whileHover={{ scale: 1.1 }}
          href="http://www.linkedin.com/in/marioveltri"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/iconLinkedin.svg" alt="Icon linkedin" />
          <h3 className={styles.text}>LinkedIn</h3>
          <p className={styles.address}>linkedin.com/in/marioveltri</p>
        </motion.a>
      </Container>
    </section>
  )
}
