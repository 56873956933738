'use client'

import { fetchPortfolioItems } from '@utils/contentful'
import { Hero } from '@components/Hero/Hero'
import { Services } from '@components/Services/Services'
import { Projects } from '@components/Projects/Projects'
import { Skills } from '@components/Skills/Skills'
import { About } from '@components/About/About'
import { Contacts } from '@components/Contacts/Contacts'
import { GetStaticProps } from 'next'
import { PortfolioItems } from '@types'

const Home = ({ items }: PortfolioItems) => {
  return (
    <main>
      <Hero />
      <Services />
      <About />
      <Projects items={items} />
      <Skills />
      <Contacts />
    </main>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const res = await fetchPortfolioItems()
  const items = await res.map((item) => {
    return {
      id: item.sys.id,
      fields: item.fields,
    }
  })

  return {
    props: {
      items,
    },
  }
}

export default Home
