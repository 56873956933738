import Image from 'next/image'
import classnames from 'classnames'
import { useScroll, motion, useTransform } from 'framer-motion'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container } from '@components/Container/Container'
import styles from './hero.module.scss'

export const Hero = () => {
  const { scrollY } = useScroll()
  const y = useTransform(scrollY, [100, 800], [0, 200])
  return (
    <motion.section id="hero" style={{ y }}>
      <Container additionalStyles={'heroContainer'}>
        <Image
          alt="Hero"
          src="/hero.jpg"
          priority
          fill
          sizes="100vw"
          style={{
            objectFit: 'cover',
            zIndex: -1,
          }}
        />
        <Container additionalStyles={'fullFlex'}>
          <div className={styles.content}>
            <h1>Mario Veltri: TAILORED DESIGN SOLUTIONS</h1>
            <p className={styles.description}>
              Mario Veltri can provide all services needed by yourself or your
              company to push your image to the next level: logo, adv,
              webdesign, illustrations and so on…
            </p>
            <AnchorLink
              href="#projects"
              className={classnames('linkAsBtn', 'button')}
            >
              TELL ME MORE
            </AnchorLink>
          </div>
        </Container>
      </Container>
    </motion.section>
  )
}
