import { useState, useEffect } from 'react'

export const useUserAgent = () => {
  const [isSafari, setIsSafari] = useState({
    isSafari: false,
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const chromeAgent =
        window.navigator.userAgent.toLowerCase().indexOf('chrome') === -1
      const safariAgent =
        window.navigator.userAgent.toLowerCase().indexOf('safari') !== -1
      if (chromeAgent && safariAgent) {
        setIsSafari({
          isSafari: true,
        })
      }
    }
  }, [])
  return isSafari
}
